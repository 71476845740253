
import {
  computed, defineComponent, PropType, toRefs,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import { useI18n } from '@/plugins/vue-i18n';
import { UUID } from '@/domains/common';
import {
  CourseTreeNodeType,
  isCourseStepAtom,
  useCoursePlayerProvider,
} from '@/domains/course';
import TrackSidebar from '@/components/tracks/TrackSidebar/TrackSidebar.vue';
import { ButtonsTypes } from '@/domains/assignment';
import { AtomType } from '@/domains/atom';

// const userHelpers = createNamespacedHelpers('user');
// const companyStore = createNamespacedHelpers('company');

// const analyzedPages: string[] = [
//   Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_SCORM_VIEW,
//   Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTICONTENT,
// ];

export default defineComponent({
  name: 'CoursePlayer',

  components: {
    TrackSidebar,
  },

  inject: ['Names'],

  props: {
    assignmentId: {
      type: Number,
      required: true,
    },

    trackSessionId: {
      type: Number,
      default: null,
    },

    initialStepId: {
      type: [String, null] as PropType<UUID | null>,
      default: null,
    },
  },

  setup(props) {
    const {
      trackSessionId,
      initialStepId,
    } = toRefs(props);

    const router = useRouter();
    const { t } = useI18n();

    const isMyOwnResults = computed(() => true);

    const {
      currentStep,
      isLoading,
      isError,
      isCurrentStepCompleted,
      isCurrentStepLoading,
      course,
      steps,
      courseCompletionRate,
      assignmentFinishData,
      onStepNextHandler,
      onStepPrevHandler,
      onStepCompleteHandler,
      onStepSetHandler,
      onNewVersionLoaded,
      courseSession,
      isNextStepAvailable,
      isPrevStepAvailable,
      isLinearFlow,
    } = useCoursePlayerProvider({
      trackSessionId,
      initialStepId,
      router,
    });

    // TODO: разделить на отдельные состояния для кнопок, кнопки вынести в этот компонент
    const buttonsState = computed(() => ({
      [ButtonsTypes.prev]: {
        enabled: isPrevStepAvailable.value,
        isLoading: false,
      },
      [ButtonsTypes.next]: {
        enabled: isNextStepAvailable.value,
        isLoading: isLoading.value,
        showTooltip: currentStep.value?.current.itemType === CourseTreeNodeType.STEP
          ? currentStep.value?.current?.atomType === AtomType.QUIZ && !isNextStepAvailable.value
          : false,
        tooltipText: t('StepsButtons.nextButtonTooltipTextQuiz'),
      },
      isShowNextStepLinearWarning: !isNextStepAvailable.value && isLinearFlow.value,
    }));

    return {
      currentStep,
      isLoading,
      isError,
      isCurrentStepCompleted,
      isCurrentStepLoading,
      course,
      steps,
      courseCompletionRate,
      assignmentFinishData,
      onStepNextHandler,
      onStepPrevHandler,
      onStepCompleteHandler,
      onStepSetHandler,
      onNewVersionLoaded,
      isMyOwnResults,
      buttonsState,
      courseSession,
      isCourseStepAtom,
    };
  },
});
